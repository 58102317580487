<template>
    <div class="infomation">
        <div class="swiper-container pageTop_box" id="infomationTop_swiper">
            <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="(item,i) in banner" :key="i">
                    <div class="bg_img" :style="'background-image: url('+item+')'"></div>
                </div>
            </div>
            <div class="pagination infomationTop-pagination"></div>
        </div>
        <div class="first_box box_">
            <!-- 头条新闻 -->
            <div class="floatLf infomation_1_box">
                <div class="infomation_title_box">
                    <div class="lineLeft_box_title">{{hot_news.name||'头条新闻'}}</div>
                </div>
                
                <div class="content"  @click="onInfoDetail(hotItem.id)">
                    <div class="swiper-container img_box" id="infomation1_swiper">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide" v-for="item in hot_news.list" :key="item.id">
                                <div class="bg_img" :style="'background-image: url('+item.thumbnail+')'"></div>
                            </div>
                        </div>
                        <div class="infomation1-pagination"></div>
                    </div>
                    <div class="title ellipsis" >{{hotItem.title}}</div>
                </div>
            </div>

            <!-- 领导关怀     -->
            <div class="floatRt infomation_2_box">
                <div class="infomation_title_box">
                    <div class="lineLeft_box_title">{{leadershipCare.name||"领导关怀"}}</div>
                    <button class="than_more"  @click="onGetMore(leadershipCare.name, leadershipCare.category_id)">查看更多></button>
                </div>

                <div class="scrollbar_box list_box">
                    <div v-for="item in leadershipCare.list" :key="item.id" @click="onInfoDetail(item.id)">
                        <div class="img_box" :style="'background-image: url('+item.thumbnail+')'"></div>
                        <div class="content_">
                            <div class="name">{{item.title}}</div>
                            <div class="bottom_">
                                <span>{{item.time|set_time}}</span>
                                <span>查看详情></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="second_box box_">
            <!-- 行业资讯 -->
            <div class="floatLf infomation_3_box">
                <div class="infomation_title_box">
                    <div class="lineLeft_box_title">{{industryInfor.name||'行业资讯'}}</div>
                    <button class="than_more" @click="onGetMore(industryInfor.name, industryInfor.category_id)">查看更多></button>
                </div>
                <div class="list_box scrollbar_box">
                    <div v-for="item in industryInfor.list" :key="item.id" @click="onInfoDetail(item.id)">
                        <div class="ellipsis title">{{item.title}}</div>
                        <div class="date_str">{{item.time|set_time}}</div>
                    </div>
                </div>
            </div>

            
            <!-- 活动中心 -->
            <div class="floatRt infomation_4_box">
                <div class="infomation_title_box">
                    <div class="lineLeft_box_title">{{activityCenter.name||'活动中心'}}</div>
                    <button class="than_more" @click="onGetMore(activityCenter.name, activityCenter.category_id)">查看更多></button>
                </div>
                <div class="content_">
                    <div class="left_">
                        <div class="img_box" :style="'background-image: url('+selectActivityImg+')'"></div>
                    </div>
                    <div class="right_ scrollbar_box">
                        <div v-for="(item,i) in activityCenter.list" :key="item.id" @mouseenter="selectActivityIndex=i"  @click="onInfoDetail(item.id)">
                            <div class="name">{{item.title}}</div>
                            <div class="bottom_">
                                <span>{{item.time|set_time}}</span>
                                <span>查看详情></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 政策扶持 -->
        <div class="infomation_5_box">
            <div class="box_ infomation_title_box">
                <div class="lineLeft_box_title">政策扶持</div>
            </div>

            <div class="box_ tab_list">
                <div class="text_line_middle" :class="{'active':selectTabId==item.id}"
                @click="onSelectTab(item.id)"
                :style="{width: 100/city_list.length+'%'}" v-for="item in city_list" :key="item.id">
                    <span>{{item.name}}</span>
                </div>
            </div>
            <div class="info_list" >
                <div class="box_ content_" ref="infomation5_info">
                    <div v-for="item in articles_list" :key="item.id" @click="onInfoDetail(item.id)">
                        <div class="img_box"><div :style="'background-image: url('+item.thumbnail+')'"></div></div>
                        <div class="info">{{item.title}}</div>
                    </div>
                </div>
            </div>

        </div>

        <footerComponent ></footerComponent>
    </div>
</template>
<script>
export default {
    name:'infomation',
    data(){
        return{
            banner:[],
            selectTabId:0,

            hot_news:{
                list:[]
            },
            leadershipCare:{
                list:[]
            },
            hotIndex:-1,
            hotItem:{},
            industryInfor:{
                list:[]
            },
            activityCenter:{
                list:[]
            },
            selectActivityIndex:-1,
            selectActivityImg:'',

            city_list:[],
            articles_list:[]
        }
    },
    watch:{
        selectTabId:{
            handler(val){
                this.$Request({
                    url: `/portal/get_cate_list/${val}/1`,
                    method: 'get',
                }).then(({data})=>{
                    let list = data&&data.articles;
                    this.articles_list = list;

                    let el = this.$refs.infomation5_info;
                    el.classList.add('active')
                    setTimeout(() => {
                        el.classList.remove('active')
                    }, 500);
                })
                
            },
        },
        hotIndex:{
            handler(val){
                this.hotItem = this.hot_news.list[val];
            }
        },
        selectActivityIndex:{
           handler(val){
                this.selectActivityImg = this.activityCenter.list[val]&&this.activityCenter.list[val].thumbnail;
            } 
        }
    },
    created(){
        this.initData()
    },
    methods:{
        onSelectTab(id){
            this.selectTabId = id;
        },
        initData(){
            this.$Request({
                url: '/portal/news_information',
                method: 'get',
            }).then(({data})=>{
                data = data||{};
                //banner
                this.banner = this.$getListByObj(data.news).map(item=>{return item.image});
                if(this.banner.length>1){
                    this.$nextTick(()=>{
                        new Swiper ('#infomationTop_swiper', {
                            pagination: '.infomationTop-pagination',
                            paginationClickable :true,
                            autoplay: 5000,
                        })
                    })
                }
                //头条新闻
                let headlines = this.$initNes(data.headlines||{},'category_id')
                this.$set(this,'hot_news',{
                    name: headlines.name,
                    list: headlines.val
                })
                this.hotIndex = 0;
                if(headlines.val.length>1){
                    const that = this;
                    this.$nextTick(()=>{
                        new Swiper ('#infomation1_swiper', {
                            pagination: '.infomation1-pagination',
                            paginationClickable :true,
                            autoplay: 3000,
                            onSlideChangeEnd: function(swiper){
                                that.hotIndex = swiper.activeIndex
                            }
                        })
                    })
                }
                //领导关怀
                let leadership_care = this.$initNes(data.leadership_care||{},'category_id')
                this.$set(this,'leadershipCare',{
                    name: leadership_care.name,
                    list: leadership_care.val,
                    category_id: leadership_care.category_id,
                })
                //行业资讯
                let industry_information = this.$initNes(data.industry_information||{},'category_id')
                this.$set(this,'industryInfor',{
                    name: industry_information.name,
                    list: industry_information.val,
                    category_id: industry_information.category_id,
                })
                //活动中心
                let activity_center = this.$initNes(data.activity_center||{},'category_id')
                this.$set(this,'activityCenter',{
                    name: activity_center.name,
                    list: activity_center.val,
                    category_id: activity_center.category_id,
                })
                this.selectActivityIndex = 0;
                //政策扶持
                let cate = this.$initNes(data.cate||{})
                this.$set(this,'city_list',cate.val)
                this.selectTabId = cate.val[0]&&cate.val[0].id
            })
        },
        onInfoDetail(id){
            this.$router.push({name:'infomationDetail', query:{id: id}})
        },
        onGetMore(name, id){
            this.$router.push({name:'infomationList', query:{name: name, id: id}})
        }
    }
}
</script>
<style lang="scss" scoped>
.infomation{
    text-align: center;
    .pageTop_box{
        width: 100%;
        height: 450px;
    }
    .box_{
        width: 1200px;
        margin: 0 auto;
    }
    .infomation_title_box{
        text-align:left;
        padding-bottom: 22px;
        border-bottom: 1px solid #666666;
        position: relative;
        .than_more{
            position: absolute;
            right: 0;
            bottom: 19px;
        }
    }
    .first_box,
    .second_box{
        display: inline-block;
        >div{
            width: 588px;
        }
    }
    .first_box{
        margin-top: 75px;
    }
    .infomation_1_box{
        .content{
            margin-top: 20px;
            background: #FFFFFF;
            box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.12);

            .img_box{
                width: 100%;
                height: 330px;
                position: relative;
                cursor: pointer;
                .bg_img{
                    width: 100%;
                    height: 100%;
                    @include backImg('../assets/img/home_6_1.png');
                    background-color: #ccc;
                }
                .infomation1-pagination{
                    z-index: 9;
                    position: absolute;
                    padding: 0 20px;
                    bottom: 20px;
                    text-align: right;
                }
            }
            .title{
                cursor: pointer;
                text-align:left;
                height: 56px;
                line-height: 56px;
                padding: 0 20px;
                font-size: 14px;
                color: #333;
            }
        }
    }
    .infomation_2_box{
        .list_box{
            width: 100%;
            height: 408px;
            overflow-y: auto;
            >div{
                &:nth-child(1){
                    margin-top: 20px;
                }
                cursor: pointer;
                transition: all .2s ease;
                &:not(:last-child){
                    margin-bottom: 7px;
                }
                .img_box{
                    display: inline-block;
                    vertical-align: middle;
                    width: 222px;
                    height: 124px;
                    background: #CCCCCC;
                    @include backImg('../assets/img/home_6_1.png');
                }
                .content_{
                   display: inline-block; 
                    vertical-align: middle;
                   width: calc(100% - 225px);
                   padding-left: 18px;
                   .name{
                        text-align: left;
                        font-size: 14px;
                        color: #333333;
                        line-height: 21px; 
                        @include textOver(2)
                   }
                   .bottom_{
                        font-size: 12px;
                        line-height: 21px;
                        color: #999;
                        margin-top: 8px;
                        >span{
                            display: inline-block;
                            width: 50%;
                        }
                        >span:first-child{
                            text-align: left;
                        }
                        >span:last-child{
                            text-align: right;
                        }
                   }
                }
                &:hover{
                    transform: translateY(-5px);
	                box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.12);
                    .content_ .name{
                        color: $lv;
                    }
                }
            }
        }
    }
    .second_box{
        margin-top: 40px;
    }
    .infomation_3_box{
        .list_box{
            margin-top: 10px;
            height: 270px;
            overflow-y: auto;
            >div{
                width: 100%;
                color: #333333;
                line-height: 21px;
                padding: 5px 0;
                cursor: pointer;
                >div{
                    display: inline-block;
                    vertical-align: middle;
                }
                .title{
                    font-size: 14px;
                    width: calc(100% - 90px);
                    text-align: left;
                }
                .date_str{
                    color: #999;
                    width: 90px;
                    text-align: right;
                }
                &:hover{
                    color: $lv;
                }
            }
        }
    }
    .infomation_4_box{
        .content_{
            margin-top: 20px;
            >div{
                display: inline-block;
                vertical-align: top;
            }
            .left_{
                width: 342px;
                height: 260px;
                >div{
                    width: 100%;
                    height: 100%;
                    background: #CCCCCC;  
                    @include backImg('../assets/img/home_6_1.png');
                } 
            }
            .right_{
                width: calc(100% - 342px);
                height: 270px;
                overflow-y: auto;
                overflow-x: hidden;
                >div{
                    min-height: 88px;
                    padding: 10px 10px 0 14px;
                    color: #333333;
                    cursor: pointer;
                    transition: all .2s ease;
                    .name{
                        text-align: left;
                        font-size: 14px;
                        line-height: 21px; 
                        @include textOver(2)
                   }
                   .bottom_{
                        font-size: 12px;
                        line-height: 21px;
                        color: #999;
                        margin-top: 5px;
                        >span{
                            display: inline-block;
                            width: 50%;
                        }
                        >span:first-child{
                            text-align: left;
                        }
                        >span:last-child{
                            text-align: right;
                        }
                   }
                   
                   &:hover{
                        transform: translateY(-10px);
                        color: $lv;
                        box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.12);
                   }
                }    
            }
        }
    }
    .infomation_5_box{
        margin-top: 40px;
        .tab_list{
            margin-top: 20px;
            text-align: left;
            &>div{
                max-width: 25%;
                display: inline-block;
                vertical-align: middle;
                height: 80px;
                background-color: #FFFFFF;
                font-size: 20px;
                font-weight: bold;
                color: #999999;
                text-align:center;
                cursor: pointer;
                &.active{
                    color: $lv;
                    background: #F5F5F5;
                }
                &:hover{
                    color: $lv;
                }
            }
        }
        .info_list{
            padding: 40px 0 20px;
            background: #F5F5F5;
            text-align: center;
            .content_{
                display: inline-block;
                text-align: left;
                &.active{
                    animation: slideInLeft 0.5s linear forwards;
                }
                &>div{
                    float: left;
                    width: 385px;
                    background: #cccccc;
                    box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.12);
                    margin-bottom: 20px;
                    cursor: pointer;
                    &:not(:nth-child(3n)){
                        margin-right: 18px;
                    }
                    .img_box{
                        width: 100%;
                        height: 218px;
                        overflow: hidden;
                        &>div{
                            width: 100%;
                            height: 100%;
                            @include backImg('../assets/img/home_6_1.png');
                            transition: all .3s ease;  
                            background-color: #ccc;
                        }
                    }
                    .info{
                        background-color: #fff;
                        padding: 15px 16px;
                        min-height: 80px;
                        font-size: 18px;
                        color: #333333;
                        line-height: 24px;
                        @include textOver(2)
                    }
                    &:hover{
                        .img_box>div{
                            transform: scale(1.2);
                        }
                    }
                }
            }
        }
    }
}

</style>

<style lang="scss">
.infomation1-pagination{
    &>span{
        width: 10px;
        height: 10px;
        &.swiper-pagination-bullet-active{
            background-color: $lv;
        }
    }
}
</style>